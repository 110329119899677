<div class="d-flex justify-content-center">
    <div class="signup-container userlogin-pg box-shadow"
        style="background-image: url('../assets/app-images/background_image_.png')">
        <div class="row d-flex flex-row flex-wrap row-style">

            <div class="image-logo-container d-flex flex-column justify-content-start">
                <div class="green-lines d-flex flex-column justify-content-end order-sm-1">
                    <div class="dark-green-bar"></div>
                    <div class="light-green-bar"></div>
                </div>

                <div class="conext-insight-logo d-flex justify-content-start flex-nowrap order-sm-2">
                    <div class="logo-empty-container"></div>
                    <div class="logo-fill-container d-flex flex-column flex-nowrap align-self-center">
                        <!-- <h1 style="color: #3DCD58;">Insight<sub>Cloud</sub></h1> -->
                        <!-- <span class="conextBrandName">Insight</span>
                        <span class="cloudBrandName">Cloud</span> -->
                        <span>
                            <p style="display:inline;" class="conextBrandName3">Insight</p>
                            <p style="display:inline;" class="cloudBrandName3">Cloud</p>
                            <!-- <h6 class="font-weight-light" style="color:#9EE6AC;">Version 2.0</h6> -->
                        </span>
                    </div>
                </div>

                <div class="schneider-copyright-logo d-flex flex-column justify-content-end order-sm-3">
                    <div class="copyright-container d-flex flex-column justify-content-end">
                        <div class="d-flex justify-content-start flex-nowrap">
                            <div class="logo-empty-container"></div>
                            <div class="hidden-xs" style="text-align: left; color: rgb(209, 203, 203);">
                                <!-- <span id="j_id0:j_id30">
                                    This application is protected by copyright law and international treaties.
                                    <a class="privacy-link" onclick="window.open('','_blank');"
                                        style="cursor: pointer;"></a>
                                    <br>
                                </span>
                                <span id="j_id0:j_id34">©&nbsp;2019 Schneider Electric Industries SAS. All Rights
                                    Reserved.</span> -->
                                <span id="j_id0:j_id30">
                                    This application is protected by copyright law and international treaties. <a
                                        class="privacy-link" onclick="window.open('','_blank');"
                                        style="cursor: pointer;"></a>
                                    <br></span><span id="j_id0:j_id34">
                                    ©&nbsp;2022 Schneider Electric Industries SAS. All Rights Reserved.</span><br>
                                <span id="j_id0:j_id34" (click)="goToSchneiderWebPortalForDataPrivacyAndCookiePolicy()"
                                    class='privacyAndCookieOnLogin'>Data Privacy and Cookie Policy</span>
                            </div>
                        </div>
                    </div>
                    <div class="sch-logo-container d-flex flex-row justify-content-around">
                        <img class="life-is-on align-self-center" [src]="schneiderLogo">
                        <!-- <img class="life-is-on align-self-center"
                            src="https://secureidentity.schneider-electric.com/identity/resource/1544267078000/IDMS_PRM_POMP_Images/img/img02-1.png"> -->
                    </div>
                </div>
            </div>
            <div class="form-container">
                <div class="form-details">
                    <div *ngIf='registerSuccess' class='thankyouwrapper'>
                        <div class='w76'>
                            <div class='registerSuccess'></div>
                            {{registerSuccessMsg}}
                        </div>
                        <a routerLink="/login" style="cursor: pointer;" routerLinkActive="active">Login</a>
                        <br>
                        <br>
                        <br>
                        <h6 *ngIf="!isMessageSent">Not Received e-mail ?</h6>
                        <h6 *ngIf="!isMessageSent" class="hyperlink-blue hyperLink-cursor-style"
                            (click)="resendEmailVerification()"><u>Resend email confirmation</u>
                        </h6>
                        <h6 *ngIf="isMessageSent" style="color:#3dcd58;">
                            E-mail Sent Successfully, please check your mailbox
                        </h6>
                        <small *ngIf="isMessageSent">Please check spam folder or firewall settings if not
                            received</small>
                        <br>
                        <br>
                        <h6>Still facing trouble ?</h6>
                        <h6 class="hyperlink-blue hyperLink-cursor-style" (click)="goToSESupportWebPortal()">Contact SE
                            Solar Support</h6>
                    </div>

                    <form *ngIf='!registerSuccess' (ngSubmit)="onSubmit()" #f="ngForm"
                        class="form-horizontal needs-validation" novalidate>
                        <div class="">
                            <div class="form-group padd-bt-13" dir="auto">
                                <div class="input-group input-group-sm"
                                    [ngClass]="{'was-validated': myEmail.touched==true}">
                                    <div class="input-group-prepend" style="height: 31px;">
                                        <div class="input-group-text">
                                            <!-- <span class="oi oi-person"></span> -->
                                            <svg focusable="false" width="11px" height="13px" viewBox="0 0 32 26"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                                                class="auth0-lock-icon auth0-lock-icon-box">
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none"
                                                    fill-rule="evenodd" sketch:type="MSPage">
                                                    <g id="32px" sketch:type="MSLayerGroup"
                                                        transform="translate(-2155.000000, -2317.000000)"
                                                        fill="#373A39">
                                                        <g id="Group-856" transform="translate(1.000000, 1.000000)"
                                                            sketch:type="MSShapeGroup" fill="#888888">
                                                            <path id="Fill-419"
                                                                d="M2184,2339 C2184,2339.55 2183.55,2340 2183,2340 L2157,2340 C2156.45,2340 2156,2339.55 2156,2339 L2156,2319 C2156,2318.45 2156.45,2318 2157,2318 L2183,2318 C2183.55,2318 2184,2318.45 2184,2319 L2184,2339 L2184,2339 Z M2184,2316 L2156,2316 C2154.89,2316 2154,2316.89 2154,2318 L2154,2340 C2154,2341.1 2154.89,2342 2156,2342 L2184,2342 C2185.1,2342 2186,2341.1 2186,2340 L2186,2318 C2186,2316.89 2185.1,2316 2184,2316 L2184,2316 Z M2176,2322 L2180,2322 L2180,2326 L2176,2326 L2176,2322 Z M2174,2328 L2182,2328 L2182,2320 L2174,2320 L2174,2328 Z M2158,2332 L2172,2332 L2172,2330 L2158,2330 L2158,2332 Z M2158,2336 L2172,2336 L2172,2334 L2158,2334 L2158,2336 Z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <input autocomplete="off" value="" maxlength=100 [(ngModel)]="email" name="email"
                                        type="text" pattern="[a-zA-Z0-9._+-]+@[a-zA-Z 0-9.-]+\.[a-zA-Z]{2,5}$"
                                        (blur)="isUserExist($event)" class="form-control greyColorInput emailClass"
                                        (ngModelChange)="validateEmailId($event)" placeholder="johndoe@xyz.com" required
                                        email #myEmail="ngModel">
                                    <!-- placeholder="Email (Required)" required email #myEmail="ngModel" onKeyDown="if(event.keyCode === 32)
                    return false;"> -->
                                    <!-- <small id="emailHelpBlock" *ngIf="!myEmail.valid && myEmail.touched"
                      class="form-text text-muted err-msg">
                      Please enter valid email and must not contain spaces or emoji.
                    </small> -->
                                </div>
                                <div *ngIf="!isUsernameAvailable" class="text-danger">
                                    <small>{{isUsernameAvailableMsg}}</small>
                                </div>
                                <div id="emailHelpBlock" *ngIf="!incorrectEmailId" class="form-text text-muted err-msg">
                                    Invalid email Id format.
                                </div>
                                <div id="emailHelpBlock" *ngIf="incorrectEmailChars"
                                    class="form-text text-muted err-msg">
                                    Your password can't start or end with a blank space.
                                </div>
                                <div id="emailHelpBlock" *ngIf="!myEmail.valid && myEmail.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myEmail.hasError('required')">Email Id is required.</div>
                                    <div [hidden]="!myEmail.hasError('pattern')">Only alphabets and numbers . _ + - @
                                        are allowed.
                                    </div>
                                </div>

                            </div>

                            <div class="form-group padd-bt-13 passwordWrapper" dir="auto">
                                <div class="input-group input-group-sm"
                                    [ngClass]="{'was-validated': myPassword.touched==true}">
                                    <div class="input-group-prepend" style="height: 31px;">
                                        <div class="input-group-text">
                                            <!-- <span class="oi oi-lock-locked"></span> -->
                                            <svg focusable="false" width="11px" height="14px" viewBox="0 0 13 16"
                                                version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                class="auth0-lock-icon auth0-lock-icon-box">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-288.000000, -1508.000000)" fill="#888888">
                                                        <path
                                                            d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="password-input-container">
                                            <input [type]="typePassword ? 'text' : 'password'" autocomplete="new-password"
                                                value="" class="form-control" [ngModel]="password" name="password"
                                                id="inputPassword" (ngModelChange)="validatePassWordForSpclChar($event)"
                                                required minlength=8 maxlength=25 placeholder="Password"
                                                #myPassword="ngModel" (click)="onKeyPress()">
                                            <i (click)="togglePassword()" class="showPassword fa"
                                                [ngClass]="{'fa-eye-slash': !typePassword,'se-eye': typePassword}"
                                                [ngStyle]="{'z-index': showEyeIcon ? 9 : 0 }"></i>
                                        </div>
                                        <div class="pass-err-container" *ngIf="!validPassword && myPassword.dirty">
                                            <div class="pass-err-inner">
                                                <div>
                                                    <p class="err-header" style="padding-bottom: 7px;"> Password must meet the following requirements: </p>
                                                    <p class="err-msg{{isMinLengthFail ? 2 : ''}}"> {{isMinLengthFail ? '✔' : '✘'}} At least 8 characters in length </p>			
                                                    <p class="err-msg{{isLC ? 2 : ''}}"> {{isLC ? '✔' : '✘'}} Lower case letters (a-z) </p>
                                                    <p class="err-msg{{isUC ? 2 : ''}}"> {{isUC ? '✔' : '✘'}} Upper case letters (A-Z) </p>
                                                    <p class="err-msg{{isNumeric ? 2 : ''}}"> {{isNumeric ? '✔' : '✘'}} Numbers (0-9) </p>
                                                    <p class="err-msg{{isSpclChar ? 2 : ''}}"> {{isSpclChar ? '✔' : '✘'}} Special characters (!@#$%&^*) </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myFullName.touched == true}">
                                <input type="text" [(ngModel)]="firstName" name="firstName"
                                    class="w100 form-control greyColorInput" placeholder="John doe"
                                    #myFullName="ngModel" required [pattern]="'^[a-zA-Z\'. ]*$'" minlength=1
                                    maxlength=100>

                                <div id="myFullNameError" *ngIf="!myFullName.valid && myFullName.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myFullName.hasError('required')">Name is required.</div>
                                    <div [hidden]="!myFullName.hasError('pattern')">Only alphabets and ' . are allowed.
                                    </div>
                                </div>
                            </div>

                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myOrgName.touched==true}">
                                <input type="text" [(ngModel)]="organisation" name="organisation"
                                    [pattern]="'^(?=.*[a-zA-Z])[ a-zA-Z0-9\'./!()&,-]*$'"
                                    class="w100 form-control greyColorInput" placeholder="abc company (Optional)"
                                    #myOrgName="ngModel" minlength=1 maxlength=50>
                                <!-- <small id="myOrgNameError" *ngIf="!myOrgName.valid && myOrgName.touched"
                    class="form-text text-muted err-msg">
                    Please enter a valid organisation name.
                  </small> -->
                                <div id="myOrgNameError" *ngIf="!myOrgName.valid && myOrgName.touched"
                                    class="form-text text-muted err-msg">
                                    <!-- <div [hidden]="!myOrgName.hasError('required')">Organisation name is required</div> -->
                                    <div [hidden]="!myOrgName.hasError('pattern')">Only alphabets and numbers ' / . ( )
                                        , & - ! are allowed.
                                    </div>
                                    <div [hidden]="!myOrgName.errors.maxlength">Organisation maximum 100 characters
                                        only.</div>
                                </div>
                            </div>
                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myPhoneNumber.touched==true}">
                                <input type="text" onlyNumberAllowed [(ngModel)]="phone" name="phone"
                                    class="w100 form-control greyColorInput" placeholder="123456789 (Optional)"
                                    #myPhoneNumber="ngModel" minlength=10 maxlength=15>
                                <!-- <small id="myPhoneError" *ngIf="!myPhoneNumber.valid && myPhoneNumber.touched"
                    class="form-text text-muted err-msg">
                    Please enter a valid phone number.
                  </small> -->
                                <div id="myPhoneError" *ngIf="!myPhoneNumber.valid && myPhoneNumber.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myPhoneNumber.hasError('minlength')">Phone number should be 10
                                        digit.</div>
                                    <!-- <div [hidden]="!myPhoneNumber.hasError('maxlength')">Phone number maximum 15 numbers only</div> -->
                                    <!-- <div [hidden]="!myPhoneNumber.hasError('pattern')">Phone number should be only numbers</div> -->
                                </div>
                            </div>
                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myAddress.touched==true}">
                                <input type="text" [(ngModel)]="address" name="address"
                                    class="w100 form-control greyColorInput" placeholder="#01, abc street"
                                    [pattern]="'^(?=.*[a-zA-Z])[ a-zA-Z0-9\'./#()&,-]+$'" #myAddress="ngModel"
                                    minlength=5 maxlength=200 required>
                                <!-- <small id="myAddressError" *ngIf="!myAddress.valid && myAddress.touched"
                    class="form-text text-muted err-msg">
                    Please enter a valid address.
                  </small> -->
                                <div id="myAddressError" *ngIf="!myAddress.valid && myAddress.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myAddress.hasError('minlength')">Address must be at least 5
                                        characters.</div>
                                    <div [hidden]="!myAddress.hasError('required')">Address is required.</div>
                                    <div [hidden]="!myAddress.hasError('pattern')">Only alphabets and numbers ' . / # (
                                        ) & , - are allowed.
                                    </div>
                                </div>
                            </div>

                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myCity.touched==true}">
                                <input type="text" [(ngModel)]="city" name="city"
                                    [pattern]="'^(?=.*[a-zA-Z])[ a-zA-Z\'.-]+$'" minlength="2" maxlength="50"
                                    class="w100 form-control greyColorInput" placeholder="My city" #myCity="ngModel"
                                    required>
                                <small id="myCityError" *ngIf="!myCity.valid && myCity.touched"
                                    class="form-text text-muted err-msg">
                                    Please enter a valid city.
                                </small>
                                <div id="myCityError" *ngIf="!myCity.valid && myCity.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myCity.hasError('minlength')">City must be at least 2 characters.
                                    </div>
                                    <div [hidden]="!myCity.hasError('pattern')">Only alphabets and . - ' are allowed.
                                    </div>
                                </div>
                            </div>

                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myState.touched==true}">
                                <input type="text" [(ngModel)]="state" name="state"
                                    [pattern]="'^(?=.*[a-zA-Z])[ a-zA-Z0-9\'.(),-]+$'" minlength="2" maxlength="50"
                                    class="w100 form-control greyColorInput" placeholder="My state" #myState="ngModel"
                                    required>
                                <small id="myStateError" *ngIf="!myState.valid && myState.touched"
                                    class="form-text text-muted err-msg">
                                    Please enter a valid state.
                                </small>
                                <div id="myStateError" *ngIf="!myState.valid && myState.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myState.hasError('minlength')">State must be at least 2 characters.
                                    </div>
                                    <div [hidden]="!myState.hasError('pattern')">Only alphabets and space - . ' ( ) , "
                                        are allowed.</div>
                                </div>
                            </div>

                            <div class="input-group input-group-sm mb-3"
                                [ngClass]="{'was-validated': myZip.touched==true}">
                                <input type="text" [(ngModel)]="pincode" name="pincode" minlength="3"
                                    [pattern]="'^[-0-9a-zA-Z]{3,10}$'" maxlength="10"
                                    class="w100 form-control greyColorInput" placeholder="123-1234" #myZip="ngModel"
                                    required>
                                <small id="myZipError" *ngIf="!myZip.valid && myZip.touched"
                                    class="form-text text-muted err-msg">
                                    Please enter a valid Zip.
                                </small>
                                <div id="myZipError" *ngIf="!myZip.valid && myZip.touched"
                                    class="form-text text-muted err-msg">
                                    <div [hidden]="!myZip.hasError('required')">Zip code is required.</div>
                                    <div [hidden]="!myZip.hasError('minlength')">Atleast 3 characters are
                                        required</div>
                                    <div [hidden]="!myZip.hasError('pattern')">Only numbers, alphabets and (-) are
                                        allowed.</div>
                                </div>
                            </div>
                            <div class="form-group padd-bt-13" style="padding: 0px;"
                                [ngClass]="{'was-validated': myCountry.touched==true}">
                                <label class="stylelabel float-labels labelClass1">country:</label>
                                <div class="inner-addon right-addon">
                                    <select name="country" class="custom-select-sm custom-select mb-3"
                                        [(ngModel)]="selectedCountry.name" #myCountry="ngModel" required>
                                        <option *ngFor="let country of countries" value={{country.id}}>{{country.name}}
                                        </option>
                                    </select>
                                    <svg focusable="false" width="5px" height="10px" viewBox="0 0 5 10" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                                        class="rightIcon auth0-lock-icon-arrow">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                            sketch:type="MSPage">
                                            <g id="Lock" transform="translate(-396.000000, -3521.000000)" fill="#000000"
                                                opacity="0.539999962">
                                                <g id="SMS" transform="translate(153.000000, 3207.000000)">
                                                    <g transform="translate(35.000000, 299.000000)">
                                                        <g
                                                            transform="translate(210.000000, 20.000000) rotate(-90.000000) translate(-210.000000, -20.000000) translate(198.000000, 8.000000)">
                                                            <path id="Shape" d="M7,10 L12,15 L17,10 L7,10 Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" style="position: absolute;" class="custom-control-input"
                                    id="customControlAutosizing" (change)="FieldsChange($event)" required>
                                <label class="custom-control-label" for="customControlAutosizing">
                                    <div class='termsTxt'>I have read understand and agree with the</div>
                                    <button class="btn btn-sm btn-link terms" type="button" (click)="open(content)">
                                        <div>Terms of Use & Data Privacy Statement</div>
                                    </button>
                                </label>

                                <small id="passwordHelpBlock" *ngIf="isSubmitClicked && !isCheckedTC"
                                    class="form-text text-muted err-msg">
                                    Please check this box.
                                </small>
                            </div>

                            <div class="input-group input-group-sm mb-3">
                                <re-captcha style="transform:scale(0.8);transform-origin:0;-webkit-transform:scale(0.8);
                    transform:scale(0.8);-webkit-transform-origin:0 0;transform-origin:0 0;width:250px"
                                    #captchaRef="reCaptcha" (resolved)="resolved($event)" siteKey={{siteKey}}>
                                </re-captcha>

                                <small id="captchaHelpBlock" *ngIf="isSubmitClicked && !captchaChecked"
                                    class="form-text text-muted err-msg">
                                    Please check captcha.
                                </small>
                            </div>
                            <div class="form-group">
                                <small id="passwordHelpBlock" *ngIf="isSubmitClicked && !isUsernameAvailable"
                                    class="form-text text-muted err-msg">
                                    {{isUsernameAvailableMsg}}
                                </small>
                                <!-- <input class="btn btn-default btn-block active-btn mt-4"
                    [disabled]="!(f.valid  && validPassword && captchaChecked)" type="submit" value="Sign up"> -->
                                <button *ngIf="!signUpState"
                                    [ngClass]="{'buttonload': !(f.valid  && validPassword && captchaChecked) == false, 'buttonloadDisabled': !(f.valid  && validPassword && captchaChecked) == true || isNotAllowedChar || isIC || incorrectEmailChars}"
                                    [disabled]="!(f.valid  && validPassword && captchaChecked) || isNotAllowedChar || !incorrectEmailId || isIC">Sign
                                    up</button>
                                <button *ngIf="signUpState" class="buttonload" [disabled]="true"><i
                                        class="fa fa-refresh fa-spin"></i>&nbsp;&nbsp;Signing up...</button>
                            </div>
                        </div>

                        <div class="float-right">
                            <button type="button" class="btn btn-link cancel" (click)="redirect()">Cancel</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #signUpErrorDialogTemplate let-c="close" let-d="dismiss" style="width:500px;">
    <div class="modal-header">
        <h4 class="modal-title">Error</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-2">
                <img [src]="errorModalImage" height="50" width="50" />&nbsp;&nbsp;&nbsp;
            </div>
            <div class="col-sm-10">
                <span>{{showMessage}}</span>
            </div>
        </div>
        <br>
    </div>
    <div class="modal-footer modal-footer-css">
        <button type="button" class="btn modal-cust-btn" (click)="d('Cross click')">Close</button>
        <!-- <button *ngIf="isTrue" type="button" class="btn modal-cust-btn" (click)="resendMail()">Resend</button> -->
    </div>
</ng-template>