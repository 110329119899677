<div class="userlogin-pg container mainBgContianer box-shadow"
    style="background-image: url('../assets/app-images/background_image_with-logo.png')">
    <!---->
    <!---->
    <!---->
    <div class="signup-cove-position">
        <div class="darkGreenBar"></div>
        <div class="lightGreenBar"></div>
        <div class="signUpPanel">
            <div class="row">
                <div class="column1 col-sm-7 col-md-7 col-lg-7">
                    <div class="application-group">
                        <div class="td-cell">
                            <!---->
                            <!---->
                            <div class="">
                                <h1 class="conextBrandName">Insight</h1>
                                <h1 class="cloudBrandName">Cloud</h1>
                                <!-- <h6 class="font-weight-light" style="color:#9EE6AC;">Version 2.0</h6> -->
                            </div>
                            <!---->
                        </div>
                    </div>
                </div>
                <div class="column2 col-sm-5 col-md-5 col-lg-5">
                    <div class="signin-up index login">
                        <form class="form-horizontal" (ngSubmit)="onSubmit()" #f="ngForm"
                            class="form-horizontal needs-validation" novalidate>
                            <div class=""
                                ng-if="!uimsChangePwd &amp;&amp; resetPage !='true' &amp;&amp; mfaScreen !='true'">
                                <div class="form-group padd-bt-13" dir="auto">
                                    <label class="stylelabel float-labels labelClass1">Email:</label>
                                    <div class="inner-addon right-addon">
                                        <small id="passwordHelpBlock" class="form-text text-muted">
                                            Enter your email address below and we will send you a secure link to create
                                            a new password
                                        </small>
                                    </div>
                                </div>
                                <div class="input-group input-group-sm"
                                    [ngClass]="{'was-validated': myEmail.touched==true}">

                                    <input autocomplete="none" [(ngModel)]="emailtext" name="emailtext" type="email"
                                        pattern="[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$"
                                        (keyup)="validateEmailId($event)" class="form-control greyColorInput emailClass"
                                        placeholder="Email" required email #myEmail="ngModel">
                                    <!-- <small id="emailHelpBlock" *ngIf="!myEmail.valid && myEmail.touched"
                    class="form-text text-muted err-msg">
                    Please enter valid email and must not contain spaces or emoji.
                  </small> -->
                                </div>
                                <div id="emailHelpBlock" *ngIf="!myEmail.valid && myEmail.touched"
                                    class="form-text text-muted err-msg">
                                    <small [hidden]="!myEmail.hasError('required')">email Id is required</small>
                                    <small [hidden]="!myEmail.hasError('pattern')">(only allowed) . _ + - @ alphabets
                                        numbers
                                    </small>
                                </div>
                                <small id="emailHelpBlock" *ngIf="!incorrectEmailId"
                                    class="form-text text-muted err-msg">
                                    invalid email Id format
                                </small>

                                <div class="form-group ">
                                    <input *ngIf="!mailSendState" class="btn btn-default btn-block active-btn"
                                        type="submit" [disabled]="!f.valid || !incorrectEmailId" value="Send">
                                    <button *ngIf="mailSendState" class="buttonload" [disabled]="true"><i
                                            class="fa fa-refresh fa-spin"></i>&nbsp;&nbsp;&nbsp;Sending in...</button>
                                </div>
                            </div>

                            <div class="float-right cancelbtn">
                                <button type="button" class="btn btn-link" (click)="redirect('login')"> Cancel</button>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <footer class="sch-app">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 col-md-8 col-lg-8 copyright-note ">
                    <!-- <a class="privacy-link" style="cursor: pointer;">Privacy Policy</a> -->


                    <!---->
                    <!---->
                    <div class="hidden-xs" ng-if="isCLlabelAng == 'true'"><span id="j_id0:j_id30">
                            <!-- This application is protected by copyright law and international treaties. <a class="privacy-link"
                onclick="window.open('','_blank');" style="cursor: pointer;"></a> <br></span><span id="j_id0:j_id34">
              ©&nbsp;2020 Schneider Electric Industries SAS. All Rights Reserved.</span> -->
                            This application is protected by copyright law and international treaties. <a
                                class="privacy-link" onclick="window.open('','_blank');" style="cursor: pointer;"></a>
                            <br>
                        </span><span id="j_id0:j_id34">
                            ©&nbsp;2022 Schneider Electric Industries SAS. All Rights Reserved.</span>
                    </div>
                    <!---->
                </div>
                <!-- <div class="col-sm-4 col-md-4 col-lg-4 footer-logo">
                 <img
                        src="https://secureidentity.schneider-electric.com/identity/resource/1544267078000/IDMS_PRM_POMP_Images/img/img02-1.png">
                <img class="life-is-on align-self-center" [src]="schneiderLogo">
            </div> -->
            </div>
        </div>
    </footer>
</div>