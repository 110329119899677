<div class="userlogin-pg container mainBgContianer box-shadow"
    style="background-image: url('assets/app-images/background_image_with-logo.png')">
    <!---->
    <!---->
    <!---->
    <div class="signup-cove-position">
        <div class="darkGreenBar"></div>
        <div class="lightGreenBar"></div>
        <div class="signUpPanel">
            <div class="row">
                <div class="column1 col-sm-7 col-md-7 col-lg-7">
                    <div [ngClass]="{'application-group': !isValidTabCss, 'application-group-tab': isValidTabCss}">
                        <div class="td-cell">
                            <div class="version-postion">
                                <!-- <h1>Conext&trade; Insight 2</h1> -->
                                <h1 class="conextBrandName">Insight</h1>
                                <h1 class="cloudBrandName">Cloud</h1>
                                <!-- <h6 class="version-color font-weight-light">Version 2.0</h6> -->
                            </div>
                            <br>
                            <div class="version-postion">
                                <p class="downloadMsg" *ngIf="!isValidTabCss">Download InsightMobile SE.</p>
                            </div>
                            <div class="version-postion">
                                <img [ngClass]="{'pb-1': isValidTabCss}" src="assets/app-images/apple-store.png"
                                    class="store-btn appStoresCustomStyle" alt="" (click)="goToAppleStore()">&nbsp;
                                <img src="assets/app-images/google-playstore.png" class="store-btn appStoresCustomStyle"
                                    alt="" (click)="goToGoogleStore()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column2 col-sm-5 col-md-5 col-lg-5">
                    <div class="signin-up index login">
                        <form class="form-horizontal" (ngSubmit)="onSubmit()" #f="ngForm"
                            class="form-horizontal needs-validation" novalidate>
                            <div class=""
                                ng-if="!uimsChangePwd &amp;&amp; resetPage !='true' &amp;&amp; mfaScreen !='true'">
                                <div class="form-group padd-bt-13" dir="auto">
                                    <div class="input-group input-group-sm"
                                        [ngClass]="{'was-validated': myEmail.touched==true}">
                                        <div class="input-group-prepend" style="height: 31px;">
                                            <div class="input-group-text">
                                                <!-- <span class="oi oi-person" style="color:gray;"></span> -->
                                                <svg _ngcontent-xtn-c3=""
                                                    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    class="auth0-lock-icon auth0-lock-icon-box" focusable="false"
                                                    height="13px" version="1.1" viewBox="0 0 32 26" width="11px"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g _ngcontent-xtn-c3="" sketch:type="MSPage" fill="none"
                                                        fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                                                        <g _ngcontent-xtn-c3="" sketch:type="MSLayerGroup"
                                                            fill="#373A39" id="32px"
                                                            transform="translate(-2155.000000, -2317.000000)">
                                                            <g _ngcontent-xtn-c3="" sketch:type="MSShapeGroup"
                                                                fill="#888888" id="Group-856"
                                                                transform="translate(1.000000, 1.000000)">
                                                                <path _ngcontent-xtn-c3=""
                                                                    d="M2184,2339 C2184,2339.55 2183.55,2340 2183,2340 L2157,2340 C2156.45,2340 2156,2339.55 2156,2339 L2156,2319 C2156,2318.45 2156.45,2318 2157,2318 L2183,2318 C2183.55,2318 2184,2318.45 2184,2319 L2184,2339 L2184,2339 Z M2184,2316 L2156,2316 C2154.89,2316 2154,2316.89 2154,2318 L2154,2340 C2154,2341.1 2154.89,2342 2156,2342 L2184,2342 C2185.1,2342 2186,2341.1 2186,2340 L2186,2318 C2186,2316.89 2185.1,2316 2184,2316 L2184,2316 Z M2176,2322 L2180,2322 L2180,2326 L2176,2326 L2176,2322 Z M2174,2328 L2182,2328 L2182,2320 L2174,2320 L2174,2328 Z M2158,2332 L2172,2332 L2172,2330 L2158,2330 L2158,2332 Z M2158,2336 L2172,2336 L2172,2334 L2158,2334 L2158,2336 Z"
                                                                    id="Fill-419"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <input autocomplete="none" [(ngModel)]="username" name="username" type="email"
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$"
                                            class="form-control greyColorInput emailClass" placeholder="Email" required
                                            email #myEmail="ngModel" (keyup)="validateEmailId($event)">
                                    </div>
                                    <div id="emailHelpBlock" *ngIf="!incorrectEmailId"
                                        class="form-text text-muted err-msg">
                                        <small> Invalid email Id format.</small>
                                    </div>
                                    <div id="emailHelpBlock" *ngIf="!myEmail.valid && myEmail.touched"
                                        class="form-text text-muted err-msg">
                                        <small [hidden]="!myEmail.hasError('required')">Email Id is required.</small>
                                        <small [hidden]="!myEmail.hasError('pattern')">Only alphabets and numbers . _ +
                                            - @ are
                                            allowed.</small>
                                    </div>
                                </div>
                                <div class="form-group" dir="auto">
                                    <div class="input-group input-group-sm"
                                        [ngClass]="{'was-validated': myPassword.touched==true}">
                                        <div class="input-group-prepend" style="height: 31px;">
                                            <div class="input-group-text">
                                                <!-- <span class="oi oi-lock-locked"></span> -->
                                                <svg focusable="false" width="11px" height="14px" viewBox="0 0 13 16"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    class="auth0-lock-icon auth0-lock-icon-box">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g transform="translate(-288.000000, -1508.000000)"
                                                            fill="#888888">
                                                            <path
                                                                d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <input [type]="typePassword ? 'text' : 'password'" autocomplete="new-password"
                                            value="" class="form-control" [ngModel]="password" name="password"
                                            id="inputPassword" (ngModelChange)="validatePassWordForSpclChar($event)"
                                            required minlength=8 maxlength=25 placeholder="Password"
                                            #myPassword="ngModel" (click)="onKeyPress()">
                                        <i (click)="togglePassword()" class="showPassword fa"
                                            [ngClass]="{'fa-eye-slash': !typePassword,'se-eye': typePassword}"
                                            [ngStyle]="{'z-index': showEyeIcon ? 9 : 0 }"></i>

                                    </div>
                                    <div class="pass-err-container" *ngIf="!validPassword && myPassword.dirty">
                                        <div class="pass-err-inner">
                                            <div>
                                                <p class="err-header" style="padding-bottom: 7px;"> Password must meet the following requirements: </p>
                                                <p class="err-msg{{isMinLengthFail ? 2 : ''}}"> {{isMinLengthFail ? '✔' : '✘'}} At least 8 characters in length </p>			
                                                <p class="err-msg{{isLC ? 2 : ''}}"> {{isLC ? '✔' : '✘'}} Lower case letters (a-z) </p>
                                                <p class="err-msg{{isUC ? 2 : ''}}"> {{isUC ? '✔' : '✘'}} Upper case letters (A-Z) </p>
                                                <p class="err-msg{{isNumeric ? 2 : ''}}"> {{isNumeric ? '✔' : '✘'}} Numbers (0-9) </p>
                                                <p class="err-msg{{isSpclChar ? 2 : ''}}"> {{isSpclChar ? '✔' : '✘'}} Special characters (!@#$%&^*) </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button
                                        [ngClass]="{'buttonload': f.valid && !isNotAllowedChar, 'buttonloadDisabled': !f.valid || isNotAllowedChar}"
                                        *ngIf="!loggingState" class="buttonload"
                                        [disabled]="!f.valid || isNotAllowedChar || !incorrectEmailId">
                                        Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-sign-in"
                                            aria-hidden="true"></i></button>
                                    <button *ngIf="loggingState" class="buttonload" [disabled]="true"><i
                                            class="fa fa-refresh fa-spin"></i>Logging in...</button>
                                </div>
                            </div>

                            <!-- Enable demo link -->

                            <div class="row" style="font-size: smaller;width: 100%;margin-top: 50px; margin-left: 22px; ">
                                <div class="col-5 pad0">
                                    <button id="forgotpassword" type="button" (click)="redirect('forgotpassword')"
                                        class="btn btn-link">Forgot
                                        password?</button>
                                </div>
                                <div class="col-1" style="padding-right: 0;"> | </div>
                                <div class="col-3 pad0 text-right">
                                    <button id="signup" type="button" (click)="redirect('signup')"
                                        class="btn btn-link">Sign up</button>
                                </div>

                            </div>

                            <!-- Enable demo link -->
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <footer class="sch-app" style="height: auto;">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 col-md-8 col-lg-8 copyright-note" style="display: block;">

                    <div class="hidden-xs" ng-if="isCLlabelAng == 'true'"><span id="j_id0:j_id30">
                            This application is protected by copyright law and international treaties. <a
                                class="privacy-link" onclick="window.open('','_blank');" style="cursor: pointer;"></a>
                            <br></span><span id="j_id0:j_id34">
                            ©&nbsp;2022 Schneider Electric Industries SAS. All Rights Reserved.</span><br>
                        <span id="j_id0:j_id34" class='privacyAndCookieOnLogin'
                            (click)="goToSchneiderWebPortalForDataPrivacyAndCookiePolicy()">Data Privacy and Cookie
                            Policy</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>

</div>

<ng-template #resetPassword>
    <div class="modalContainer">
        <div class="resetPasswordHeaderContainer">
            <h5 class="resetPasswordHeader">Reset Password</h5>
            <p class="resetPasswordHeadingPara">Please rest your password to comply with our new password policy, you may not be able to proceed without changing the password</p>
        </div>
       <form [formGroup]="resetPasswordForm">
            <div class="form-group" style="position: relative;">
                <div class="d-flex justify-content-between">
                    <label for="name">New password*</label>
                </div>
                <div style="position: relative;">
                    <input [type]="resetpasswordPasswordVisible?'text':'password'" (keyup)="validatePasswordPattern(resetPasswordForm.get('newPassword').value)" class="form-control newPasswordInp" formControlName="newPassword" maxlength="25" />   
                    <i (click)="toggleResetPassword()" class="showPassword fa"
                    [ngClass]="{'fa-eye-slash': !resetpasswordPasswordVisible,'se-eye': resetpasswordPasswordVisible}"
                    [ngStyle]="{'z-index': showEyeIcon ? 9 : 0 }"></i> 
                   
                </div>
                <div class="validationBlock">
                    <p>Password should contain: </p>
                    <p class="err-msg{{isUC ? 2 : ''}}"> {{isUC ? '✔' : '✘'}} <span style="color: #000;">Atleast one capital</span> </p>
                    <p class="err-msg{{isNumeric ? 2 : ''}}"> {{isNumeric ? '✔' : '✘'}} <span style="color: #000;">Atleast one number</span></p>
                    <p class="err-msg{{isMinLengthFail ? 2 : ''}}"> {{isMinLengthFail ? '✔' : '✘'}}<span style="color: #000;"> Atleast 8 characters</span> </p>	
                    <p class="err-msg{{isSpclChar ? 2 : ''}}"> {{isSpclChar ? '✔' : '✘'}}<span style="color: #000;"> Atleast one Special character</span> </p>		
                    
                </div>
                
            </div>
       

        
            <div class="form-group">
                <div class="d-flex justify-content-between">
                    <label for="name">Confirm New Password*</label>
                </div>
                <div style="position: relative;">
                    <input [type]="resetpasswordConfirmPasswordVisible?'text':'password'"  [class.ng-invalid]="resetPasswordForm.errors?.PasswordNoMatch" class="form-control" formControlName="confirmPassword" maxlength="25">  
                    <i (click)="toggleResetConfirmpassword()" class="showPassword fa"
                    [ngClass]="{'fa-eye-slash': !resetpasswordConfirmPasswordVisible,'se-eye': resetpasswordConfirmPasswordVisible}"
                    [ngStyle]="{'z-index': showEyeIcon ? 9 : 0 }"></i> 
                </div>
                
            </div>
       

            <div class="confirmPassword">
                <button class="buttonload loginBtn"  (click)="confirmResetPassword()"
                [class.buttonloadDisabled]="!resetPasswordForm.valid"
                [disabled]="!resetPasswordForm.valid"
                
                >Confirm</button>
            </div>
       </form>
    </div>
</ng-template>